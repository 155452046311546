import React, { Fragment, useEffect } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import ContactArea from '../../../components/ContactArea';
import Testmonial from '../../../components/Testmonial';
import FeaturedOn from '../../../components/Featured';
import { scrollToSection } from '../../../components/SmoothScrolling'; // Adjust the path as needed

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg';
import team from '../../../images/expert/jon-2.jpg';
import businessInsiderLogo from '../../../images/logo/business-insider-v2.png';
import usaTodayLogo from '../../../images/logo/usa-today-v2.png';
import laWeeklyLogo from '../../../images/logo/la-weekly-v4.png';
import ailaLogo from '../../../images/logo/aila-logo-v2.png';
import ritzHeraldLogo from '../../../images/logo/ritz-herald-v2.png';
import digitalJournalLogo from '../../../images/logo/digital_journal_logo.png';
import newLogo1 from '../../../images/logo/new_york_times_logo.jpg';
import newLogo2 from '../../../images/logo/la_times_logo.jpg';
import newLogo3 from '../../../images/logo/le_monde_logo.jpg';
import newLogo4 from '../../../images/logo/sixty_minutes.png';
import newLogo5 from '../../../images/logo/the-times-logo.jpg';
import newLogo6 from '../../../images/logo/the_washington_post_logo.jpg';
import newBrand1 from '../../../images/logo/ou_uni_logo.jpg';
import newBrand2 from '../../../images/logo/aspen_snowmass_logo.jpg';
import newBrand3 from '../../../images/logo/tyson_foods_logo.jpg';
import newBrand4 from '../../../images/logo/lucent_technologies_logo.jpg';
import newBrand5 from '../../../images/logo/hid_logo.jpg';
import newBrand6 from '../../../images/logo/colt_builder_logo.jpg';
import './style.scss';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Jon Velie' }
];

const teamDetails = {
    name: 'Jon Velie',
    position: 'Lead Counsel',
    practiceArea: 'Corporate, Litigation, Immigration',
    experience: '31 years',
    offices: 'Plano (TX), Norman (OK), Hyderabad (India)',
    phone: '(405) 310-4333',
    email: 'jon@velielaw.com',
    memberships: [
        'U.S. Supreme Court',
        '10th Circuit Court of Appeals',
        'D.C. Circuit Court of Appeals',
        'Federal District Western, Eastern, and Northern Districts of Oklahoma',
        'Oklahoma Bar Association'
    ],
    communityService: [
        'Pro bono legal work for indigenous communities',
        'Volunteer mentor for aspiring lawyers',
        'Regular speaker at law schools'
    ],
    education: [
        'Bachelor of Arts, Native American Studies, University of California, Berkeley, 1989',
        'Juris Doctor, University of Oklahoma College of Law, 1993',
    ],
    awards: [
        'Jon\'s work has been recognized with numerous awards and honors. In 2002, he was awarded the Louis M. Brown Equal Access to Justice Award by the American Bar Association (ABA) for his innovative approach to making legal services more accessible through technology. This recognition was a testament to Jon\'s commitment to using technology to improve the legal process and make it more efficient for clients.',
        'In 2014, Jon Velie\'s firm was awarded the Gold Stevie Award in the International Business Awards for Legal Services Company of the Year. This award highlighted the firm\'s success in providing exceptional legal services and its innovative business model. Jon\'s commitment to excellence in legal practice was further recognized in 2015 when the Velie Law Firm was named Best Overall Business at the Business Excellence Awards.',
        'In 2017, the firm received the Best Import/Export Business Award at the Business Excellence Awards, recognizing its work in facilitating international business through effective legal services. Jon Velie\'s contributions to human rights were also recognized by the United Nations, which awarded him the Human Rights Award for Oklahoma in 2019.',
        'Jon Velie was awarded the Silver Stevie Award for Thought Leader of The Year - Consumer Services in 2024, recognizing his leadership at OnlineVisas, Visas.AI, and Velie Global Law Firm. This award highlights Jon\'s influence as a thought leader in the legal industry and his contributions to the development of innovative legal solutions.'
    ],
    media: [
        'Jon Velie is a respected thought leader in the legal profession and has been featured in numerous media outlets throughout his career. He has been mentioned and written about in recent years in LA Weekly, USA Today, The Ritz Herald, and Digital Journal. Throughout his career, he has been featured in prestigious publications such as The New York Times, The Washington Post, The Los Angeles Times, The Times, Le Monde, and 60 Minutes, as illustrated in the attached image.'
        
    ],
    publication: [
        'In addition to his media appearances, Jon is an accomplished author and has published several articles and papers on immigration law and legal technology. His book, "Revolutionizing Immigration: How Technology is Transforming the Legal Profession," explores the challenges and opportunities presented by the integration of technology into legal practice. The book has been well-received and is considered a valuable resource for legal professionals and those interested in the future of legal services.',
        
        <a href="/files/Spotlight on Jon Velie_ Man On A Thirteen Year Mission.pdf" download="Spotlight on Jon Velie_ Man On A Thirteen Year Mission.pdf" key="download-link">
          Some of Jons publication
        </a>
    ],
    speaking: [
        'Jon Velie is a sought-after speaker and has presented at numerous conferences and seminars around the world. His speaking engagements have covered a wide range of topics, including immigration law, legal technology, and the future of the legal profession. Jon has spoken at prestigious events such as the US Open, the Global Economic Summit in Silicon Valley, and legal conferences in Shanghai, China.',
        'His ability to engage audiences with his insights and expertise has made him a popular speaker in the legal community. Jon\'s presentations are known for their depth of knowledge, practical advice, and forward-thinking approach to the challenges facing the legal profession.'
    ],
    notableClients: [
        'Throughout his career, Jon Velie has represented a diverse range of clients, including some of the most prominent names in sports, entertainment, and business. His client list includes Olympians, Oscar winners, world champions, and national champions. He has also represented Fortune 500 companies, international conglomerates, and industry leaders in securing visas and navigating complex immigration issues.',
        'Jon\'s ability to understand the unique needs of each client and provide tailored legal solutions has made him a trusted advisor to some of the most successful individuals and companies in the world. His work with high-profile clients has further established his reputation as a leading immigration attorney and legal innovator.'
    ],
    achievements: [
        'Jon Velie\'s achievements in the legal profession are a testament to his dedication, innovation, and commitment to excellence. His work has not only transformed the practice of immigration law but also set a new standard for how legal services can be delivered in the digital age. Jon\'s legacy is one of innovation, leadership, and a relentless pursuit of justice for his clients.',
        'As he continues to lead OnlineVisas and Velie Global Law Firm, Jon remains focused on driving the future of legal practice through technology and innovation. His vision for the future of immigration law and his contributions to the legal community will undoubtedly continue to shape the profession for years to come.'
    ],
    // conclusion: [
    //     'Jon Velie\'s journey as an attorney, innovator, and thought leader is a remarkable story of success, perseverance, and a commitment to making a difference in the world. From his early years as a student of the law to his current role as a leader in legal technology, Jon has consistently pushed the boundaries of what is possible in the legal profession. His work has not only impacted the lives of his clients but has also set a new standard for how legal services can be delivered in the 21st century.',
    // ],
};

const customLogos = [
    { id: 1, logo: newLogo1 },
    { id: 2, logo: newLogo2 },
    { id: 3, logo: newLogo3 },
    { id: 4, logo: newLogo4 },
    { id: 5, logo: newLogo5 },
    { id: 6, logo: newLogo6 },
    { id: 7, logo: businessInsiderLogo, alt: 'Business Insider Logo', link: 'https://www.businessinsider.com/' },
    { id: 9, logo: usaTodayLogo, alt: 'USA Today Logo', link: 'https://www.usatoday.com/' },
    { id: 10, logo: ailaLogo, alt: 'American Immigration Lawyers Association Logo', link: 'https://www.aila.org/' },
    { id: 11, logo: digitalJournalLogo, alt: 'Digital Journal Logo', link: 'https://www.digitaljournal.com/' },
    { id: 12, logo: ritzHeraldLogo, alt: 'Ritz Herald Logo', link: 'https://ritzherald.com/' },
    { id: 13, logo: laWeeklyLogo, alt: 'LA Weekly Logo', link: 'https://www.laweekly.com/' },
];

const customBrands = [
    { id: 1, logo: newBrand1 },
    { id: 2, logo: newBrand2 },
    { id: 3, logo: newBrand3 },
    { id: 4, logo: newBrand4 },
    { id: 5, logo: newBrand5 },
    { id: 6, logo: newBrand6 },
];



const SingleTeamPage = () => {
    useEffect(() => {
        const hash = window.location.hash.substring(1); // Remove the '#' from the hash
        if (hash) {
            scrollToSection(null, hash); // Pass `null` for the event parameter since it’s not a click event
        }
    }, []);

    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <section id="jon-velie-section"></section>
            <Breadcumb
                className="breadcumbArea"
                title={`Meet ${teamDetails.name}`}
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={team} alt={teamDetails.name} />
                                        </div>
                                        <h4>{teamDetails.name}</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Important Information</h4>
                                        <ul className="teamMembarInfo">
                                            <li><span>Position: </span>{teamDetails.position}</li>
                                            <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                            <li><span>Experience: </span>{teamDetails.experience}</li>
                                            <li><span>Offices: </span>{teamDetails.offices}</li>
                                            <li><span>Phone: </span>{teamDetails.phone}</li>
                                            <li><span>Email: </span>{teamDetails.email}</li>
                                        </ul>
                                        <ul className="socialShare">
                                        <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>                                           
                                        <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Personal Experience</h3>
                                        <p>As the founder and lead attorney at Velie Law Firm, PLLC, and Velie Global Firm, PLCC. Jon Velie has dedicated over 31 years to the practice of law, establishing himself as a formidable litigator and corporate law expert. His extensive experience includes briefing cases before the U.S. Supreme Court, where he secured pivotal victories, including a landmark decision on behalf of the Cherokee and Freedmen. Jon's practice spans a broad range of legal areas, from high-stakes litigation and employment law to complex corporate negotiations involving mergers, acquisitions, and intellectual property rights. Licensed across multiple jurisdictions, including the U.S. Supreme Court, Jon is a recognized leader in the legal community, having earned numerous awards for his contributions to justice and the business world. His dedication to his clients, combined with his legal acumen, has made him a trusted advisor and advocate in both national and international legal matters.</p>
                                        <h3>Early Life and Education</h3>
                                        <p>Jon Velie was born into a family that valued education, justice, and public service. His upbringing was steeped in the ideals of fairness and equality, which would later shape his career in law. Growing up in a nurturing environment, Jon was encouraged to pursue his interests and excel academically. His early exposure to legal discussions and the challenges faced by individuals in need of legal representation sparked his interest in the field of law.</p>
                                        <p>Jon pursued his undergraduate studies at the University of California, Berkeley, where he earned a Bachelor of Arts degree. Berkeley, known for its rigorous academic environment and its history of social activism, provided Jon with a broad understanding of the legal and social challenges of the time. His time at Berkeley was marked by academic excellence and a growing interest in how legal systems could be used to effect positive change.</p>
                                        <p>After completing his undergraduate studies, Jon Velie decided to further his education in law. He attended the University of Oklahoma College of Law, where he obtained his Juris Doctor (JD) degree. At law school, Jon distinguished himself as a student committed to understanding the complexities of the law and its application in real-world scenarios. His experiences during these formative years laid the foundation for a career that would see him at the forefront of legal innovation and advocacy.</p>
                                        <h3>Founding Velie Law Firm</h3>
                                        <p>In 1993, Jon Velie founded the Velie Law Firm with the mission of providing top-tier legal services in immigration and corporate law. The firm quickly became known for its innovative approach to legal practice, offering clients a combination of traditional legal expertise with modern, client-focused solutions. Jon's vision for the firm was to create a legal practice that could handle complex cases with efficiency and precision while maintaining a high level of client satisfaction.</p>
                                        <p>The Velie Law Firm gained prominence for its work on challenging and high-profile immigration cases. Jon's expertise in immigration law, combined with his commitment to client service, allowed the firm to secure visas and green cards for a wide range of clients, from Olympic athletes to entrepreneurs and investors. The firm's success in these cases helped to establish its reputation as a leader in immigration law.</p>
                                        <p>One of the key factors in the firm's success was Jon Velie's willingness to embrace innovation. He recognized early on that the traditional model of legal practice could be enhanced through the use of technology. This recognition would later lead to the creation of OnlineVisas, a platform that would revolutionize the way immigration law is practiced.</p>
                                        
                                        <h5>Memberships</h5>
                                        <ul>
                                            {teamDetails.memberships.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Community Service</h5>
                                        <ul>
                                            {teamDetails.communityService.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Education</h5>
                                        <ul>
                                            {teamDetails.education.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        <h5>Awards</h5>
                                        <ul>
                                            {teamDetails.awards.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        <h5>Media</h5>
                                        <ul>
                                            {teamDetails.media.map((item, i) => (
                                                <li key={i}>{item}</li>                                                
                                            ))}
                                        </ul>
                                        <p>Read about Jon in the media:</p>
                                        <a href="https://www.laweekly.com/the-disruption-of-law-how-onlinevisass-novel-use-of-ai-is-helping-immigration-attorneys/">LA Weekly, </a>
                                        <a href="https://www.usatoday.com/story/special/contributor-content/2023/12/19/the-best-use-of-ai-and-the-law-how-jon-velies-visas-ai-improves-lawyers-success-and-speed-with-ai-in/71975401007/">USA Today, </a>
                                        <a href="https://www.nytimes.com/2004/07/25/us/california-judge-says-courts-may-decide-tribal-disputes.html">New York Times, </a>
                                        <a href="https://www.latimes.com/archives/la-xpm-2004-jul-24-me-sbriefs24.1-story.html">Los Angeles Times, </a>
                                        <a href="https://www.washingtonpost.com/local/the-cherokees-one-nation-divisible-judge-will-decide-if-black-members-can-be-expelled/2014/05/06/8690e56c-d55e-11e3-aae8-c2d44bd79778_story.html">The Washington Post </a>
                                        <h5>Publications</h5>
                                        <ul>                                            
                                            {teamDetails.publication.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        <h5>Speaking Engagements</h5>
                                        <ul>
                                            {teamDetails.speaking.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        <h5>Notable Clients</h5>
                                        <ul>
                                            {teamDetails.notableClients.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        <FeaturedOn customBrands={customBrands} headingText="" customClass="notable-clients"/>
                                        </ul>
                                        <h5>Achievements</h5>
                                        <ul>
                                            {teamDetails.achievements.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        {/* <h5>Conclusion</h5>
                                        <p>Jon Velie's journey as an attorney, innovator, and thought leader is a remarkable story of success, perseverance, and a commitment to making a difference in the world. From his early years as a student of the law to his current role as a leader in legal technology, Jon has consistently pushed the boundaries of what is possible in the legal profession. His work has not only impacted the lives of his clients but has also set a new standard for how legal services can be delivered in the 21st century.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FeaturedOn customLogos={customLogos} headingText="Featured In" />
            <ContactArea className="contactArea" />
            <Testmonial className="testmonialArea" />
            <FooterArea />
        </Fragment>
    );
};

export default SingleTeamPage;
