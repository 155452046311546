import React, { Fragment } from 'react';
import HeaderBottom from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import ContactArea from '../../../components/ContactArea';
import Testmonial from '../../../components/Testmonial';

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg';
import team from '../../../images/expert/matthew_shclake_photoshoot.jpg';

import './style.scss';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Matthew Schlake' }
];

const teamDetails = {
    name: 'Matthew Schlake',
    position: 'Criminal Defense Attorney',
    practiceArea: 'Criminal Defense and Litigation',
    experience: '7+ years',
    office: 'Dallas-Fort Worth Metroplex',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    education: [
        'Doctor of Law - JD, University of Oklahoma College of Law (2014 - 2017)',
        'Bachelor of Arts - B.A., Criminology and Investigations, West Virginia University (2009 - 2013)'
    ],
    summary: `
        Matthew Schlake is an experienced criminal defense attorney and former prosecutor. 
        As the founder of Schlake Law Firm, he offers reliable representation for criminal matters 
        throughout the Dallas-Fort Worth Metroplex. With years of experience as a felony prosecutor, 
        Matt tirelessly fights to protect the rights of his clients.
    `,
    professionalExperience: [
        {
            role: 'Attorney At Law',
            company: 'Schlake Law Firm',
            duration: 'April 2022 - Present',
            location: 'Dallas, Texas',
            description: 'Provides legal representation for criminal matters in the DFW area.'
        },
        {
            role: 'Felony Prosecutor',
            company: 'Collin County',
            duration: 'December 2017 - April 2022',
            location: 'Texas',
            description: 'Handled felony prosecutions and represented the state in various criminal cases.'
        },
        {
            role: 'Legal Intern',
            company: 'Cleveland County District Attorney\'s Office',
            duration: 'September 2016 - February 2017',
            location: 'Norman, Oklahoma',
            description: 'Worked with the domestic division on criminal cases.'
        },
        {
            role: 'Legal Intern',
            company: 'Collin County District Attorney\'s Office',
            duration: 'June 2016 - August 2016',
            location: 'McKinney, Texas',
            description: 'Assisted with misdemeanor cases and court proceedings.'
        },
        {
            role: 'Legal Intern',
            company: 'Dallas County District Attorney\'s Office',
            duration: 'May 2016 - June 2016',
            location: 'Dallas, Texas',
            description: 'Gained experience with felony cases and legal research.'
        },
        {
            role: 'Intern',
            company: 'Oklahoma County District Attorney\'s Office',
            duration: 'May 2015 - May 2016',
            location: 'Oklahoma',
            description: 'Conducted legal research and provided support for prosecutors.'
        },
        {
            role: 'EMT-B/Firefighter',
            company: 'Star City Fire Department',
            duration: 'August 2009 - January 2014',
            location: 'Morgantown, West Virginia',
            description: 'Provided emergency medical services and firefighting support.'
        }
    ],
    skills: ['Legal Research', 'Legal Writing', 'Public Speaking'],
    linkedin: 'https://www.linkedin.com/in/matthew-schlake-3b0b6078',
};

const MatthewTeamPage = () => (
    <Fragment>
        <header className="headerArea">
            <HeaderBottom className="headerBottomArea" />
        </header>
        <Breadcumb
            className="breadcumbArea"
            title={`Meet ${teamDetails.name}`}
            breadcumbMenu={breadcumbMenu}
            background={breadcumb}
        />
        <div className="singleTeamArea">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-12">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="singleTeamImgWrap">
                                    <div className="singleTeamImg">
                                        <img src={team} alt={teamDetails.name} />
                                    </div>
                                    <h4>{teamDetails.name}</h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="singleTeamContent">
                                    <h4>Important Information</h4>
                                    <ul className="teamMembarInfo">
                                        <li><span>Position: </span>{teamDetails.position}</li>
                                        <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                        <li><span>Experience: </span>{teamDetails.experience}</li>
                                        <li><span>Office: </span>{teamDetails.office}</li>
                                        <li><span>Phone: </span>{teamDetails.phone}</li>
                                        <li><span>Email: </span>{teamDetails.email}</li>
                                    </ul>
                                    <ul className="socialShare">
                                        <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="singleTeamInfo">
                                    <h3>Professional Summary</h3>
                                    <p>{teamDetails.summary}</p>

                                    <h3>Professional Experience</h3>
                                    {teamDetails.professionalExperience.map((job, index) => (
                                        <div key={index}>
                                            <h4>{job.role} - {job.company}</h4>
                                            <p><strong>Duration:</strong> {job.duration}</p>
                                            <p><strong>Location:</strong> {job.location}</p>
                                            <p>{job.description}</p>
                                        </div>
                                    ))}

                                    <h5>Education</h5>
                                    <ul>
                                        {teamDetails.education.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>

                                    <h5>Skills</h5>
                                    <ul>
                                        {teamDetails.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactArea className="contactArea" />
        <Testmonial className="testmonialArea" />
        <FooterArea />
    </Fragment>
);

export default MatthewTeamPage;
