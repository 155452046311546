import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo/logo.png'
import './style.scss'

const footerLinks = [           
    { 
        title: 'Head Office', menus: [
            { name: '401 W Main Ste 300, Norman, Oklahoma 73069-1319', },
            { name: 'Phone: (405) 310-4333', },
            { name: 'Email: information@velielaw.com', },
        ]
    },
    { 
        title: 'Hyderabad Office', menus: [
            { name: 'KRR Residency, 3rd Floor, H.No: 16-2-100/670, Gopal Nagar, Road 1A, Hydernagar(V), Kukatpally, Hyderabad, Telangana, India - 500085' },
        ]
    },
    { 
        title: 'Dallas Office', menus: [
            { name: '555 Republic Dr. Ste 490, Plano, TX 75074', },
        ]
    },
]

const extraLinks = [
    { name: 'Naturalization', route: 'naturalization' },
    { name: 'DACA', route: 'daca-applicant' },
    { name: 'H-1B1', route: 'h1b1-visa' },
    { name: 'Contact', route: 'contact' }
    // { name: 'Blog', route: 'blog-fullwidth' }
];

    const FooterArea = () => {
        return (
            <footer className="footerArea">
                <div className="footerTopArea">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerLogo">
                                    <Link to="/">
                                        <img src={logo} alt="" />
                                    </Link>
                                    <p>American Legal Solutions for Global Clients</p>
                                </div>
                            </div>
                            {footerLinks.map((menu, i) => (
                                <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="footerWrap">
                                        <h3>{menu.title}</h3>
                                        <ul>
                                            {menu.menus.map((item, i) => (
                                                <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="footerBottomArea">
                    <div className="container">
                        <div className="footerBottomContent">
                            <div className="row">
                                <div className="col-lg-4 col-md-8">
                                    <span>Privacy Policy | © 2024 Velie Law Firm, PLLC. All rights reserved</span>
                                </div>
                                <div className="col-lg-8 col-md-4">
                                    <div className="footerExtraLinks">
                                        <div className="container">
                                            <ul className="footerExtraLinks">
                                                {extraLinks.map((link, i) => (
                                                    <li key={i}>
                                                        <Link to={`/${link.route}`}>{link.name}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                            <ul className="socialListFooter">
                                                <li>
                                                    <a href="https://www.facebook.com/VelieLawFirm/" target='_blank' rel='noopener noreferrer'><i className="fa fa-facebook" aria-hidden="true"></i>
                                                    <span className="sr-only">Facebook</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/velie-law-firm-pllc/" target='_blank' rel='noopener noreferrer'><i className="fa fa-linkedin" aria-hidden="true"></i>
                                                    <span className="sr-only">LinkedIn</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
    export default FooterArea