import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '../src/containers/app';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/css/font-awesome.min.css';
import '../src/css/flaticon.css';
import '../src/css/odometer-theme-default.css';
import '../src/css/animate.css';
import './style.scss';

import { Provider } from 'react-redux';
import store from './redux/store';



const tagManagerArgs = {
    gtmId: 'GTM-M45XC9HQ'
};

TagManager.initialize(tagManagerArgs);

// function initFacebookPixel() {
//     if (window.fbq) return;
//     window.fbq = function() {
//         window.fbq.callMethod ? window.fbq.callMethod.apply(window.fbq, arguments) : window.fbq.queue.push(arguments);
//     };
//     window.fbq.push = window.fbq;
//     window.fbq.loaded = true;
//     window.fbq.version = '2.0';
//     window.fbq.queue = [];
//     window.fbq('init', '317782647506656');
// }

// initFacebookPixel();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>,
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();