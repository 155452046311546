import React, { Fragment } from 'react';
import HeaderBottom from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import ContactArea from '../../../components/ContactArea';
import Testmonial from '../../../components/Testmonial';

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg';
import team from '../../../images/expert/drake_nelson_photoshoot.jpg';

import './style.scss';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Drake Nelson' }
];

const teamDetails = {
    name: 'Drake Nelson',
    position: 'Associate Attorney',
    practiceArea: 'Estate Planning, Articles of Incorporation, Operating Agreements, Shareholder Agreements',
    experience: '2+ years',
    office: 'Dallas, Texas',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    education: [
        'Doctor of Law - JD, Marquette University Law School (2018 - 2021)',
        'Bachelor of Science - BS, Communication and Media Studies, University of Oklahoma (August 2014 - July 2018)'
    ],
    summary: `
        Transactional Attorney at Roberts Zimmerman, PLLC, with experience drafting and reviewing contracts, 
        including company formation documents, asset purchase agreements, stock purchase agreements, and board 
        action agreements. Drake also has experience negotiating settlements, drafting real estate agreements, 
        and probate administration.
    `,
    professionalExperience: [
        {
            role: 'Associate Attorney',
            company: 'Roberts Zimmerman PLLC',
            duration: 'November 2022 - Present',
            location: 'Dallas, Texas, United States',
            description: `
                - Drafting of business entity documents, including articles of incorporation, operating agreements, 
                  transfer agreements, and shareholder agreements.
                - Drafting of estate planning documents.
                - Drafting of lease agreements, warranty deeds, promissory notes, financing statements, and deeds of trust.
                - Managing multiple concurrent projects and maintaining communication with clients and supervising attorneys.
            `
        },
        {
            role: 'Law Clerk',
            company: "United States Attorney's Office, Eastern District of Wisconsin",
            duration: 'January 2021 - May 2021',
            location: 'Milwaukee, Wisconsin, United States',
            description: `
                - Conducted rigorous legal research for civil and criminal divisions.
                - Identified issues and presented summarized findings in detailed legal briefs.
                - Built relationships with supervising attorneys and maintained excellent organizational skills.
            `
        },
        {
            role: 'Legal Intern',
            company: 'Marquette University Office of Corporate Engagement',
            duration: 'May 2019 - July 2019',
            location: 'Milwaukee, Wisconsin, United States',
            description: `
                - Assisted in creating partnership opportunities between university professors and corporate partners.
                - Coordinated meetings with corporate representatives and converted academic models into monetizable opportunities.
            `
        }
    ],
    skills: ['Negotiation', 'Research', 'Sports Law', 'Contractual Agreements', 'Licensing', 'Boxing', 'Sponsorship', 'Marketing'],
};

const DrakeTeamPage = () => (
    <Fragment>
        <header className="headerArea">
            <HeaderBottom className="headerBottomArea" />
        </header>
        <Breadcumb
            className="breadcumbArea"
            title={`Meet ${teamDetails.name}`}
            breadcumbMenu={breadcumbMenu}
            background={breadcumb}
        />
        <div className="singleTeamArea">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-12">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="singleTeamImgWrap">
                                    <div className="singleTeamImg">
                                        <img src={team} alt={teamDetails.name} />
                                    </div>
                                    <h4>{teamDetails.name}</h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="singleTeamContent">
                                <h4>Important Information</h4>
                                        <ul className="teamMembarInfo">
                                            <li><span>Position: </span>{teamDetails.position}</li>
                                            <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                            <li><span>Experience: </span>{teamDetails.experience}</li>
                                            <li><span>Office: </span>{teamDetails.office}</li>
                                            <li><span>Phone: </span>{teamDetails.phone}</li>
                                            <li><span>Email: </span>{teamDetails.email}</li>
                                        </ul>
                                        <ul className="socialShare">
                                            <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                        </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="singleTeamInfo">
                                    <h3>Summary</h3>
                                    <p>{teamDetails.summary}</p>

                                    <h3>Professional Experience</h3>
                                    {teamDetails.professionalExperience.map((job, index) => (
                                        <div key={index}>
                                            <h4>{job.role} - {job.company}</h4>
                                            <p><strong>Duration:</strong> {job.duration}</p>
                                            <p><strong>Location:</strong> {job.location}</p>
                                            <p>{job.description}</p>
                                        </div>
                                    ))}

                                    <h5>Education</h5>
                                    <ul>
                                        {teamDetails.education.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>

                                    <h5>Skills</h5>
                                    <ul>
                                        {teamDetails.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactArea className="contactArea" />
        <Testmonial className="testmonialArea" />
        <FooterArea />
    </Fragment>
);

export default DrakeTeamPage;
