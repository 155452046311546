import React, { Fragment } from 'react';
import HeaderBottom from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import ContactArea from '../../../components/ContactArea';
import Testmonial from '../../../components/Testmonial';

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg';
import team from '../../../images/expert/steve_carson_photoshoot.jpg';

import './style.scss';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Steve Carson' }
];

const teamDetails = {
    name: 'Steve Carson',
    position: 'Attorney and Senior Managing Director - Investment Banking',
    practiceArea: 'Corporate Law, Alternative Investments, Oil & Gas, Regulatory Compliance',
    experience: '30+ years',
    office: 'McKinney, Texas, United States',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    education: [
        'J.D., Law - Louis D. Brandeis School of Law, University of Louisville',
        'B.A., Business Administration - Vanderbilt University'
    ],
    summary: `
        Steve Carson is an experienced attorney with a diverse career spanning 
        investment banking, corporate law, and regulatory compliance. He holds 
        licenses with the Texas and Kentucky Bar Associations and multiple FINRA 
        registrations. With over 30 years of experience, Steve has provided legal 
        counsel across various industries, including oil and gas, private placements, 
        and alternative investments.
    `,
    professionalExperience: [
        {
            role: 'Attorney at Law',
            company: 'Stephen P. Carson, Esq.',
            duration: 'January 2014 - Present',
            location: 'Bowling Green, KY and Dallas, TX',
            description: 'Provides legal services with a focus on private finance, private placements, investment due diligence, and regulatory compliance.'
        },
        {
            role: 'Senior Managing Director - Investment Banking',
            company: 'Crescent Securities Group',
            duration: 'February 2018 - Present',
            location: 'Dallas/Fort Worth Area',
            description: 'Leads investment due diligence for alternative investment products and private placements.'
        },
        {
            role: 'General Counsel and Vice President',
            company: 'Frontier Oilfield Services, Inc.',
            duration: 'February 2013 - January 2014',
            location: 'Dallas, Texas',
            description: 'Provided legal counsel to the company during an industry downturn, specializing in oil and gas services.'
        },
        {
            role: 'President & Chief Compliance Officer',
            company: 'NGAS Securities, Inc.',
            duration: 'March 2004 - June 2011',
            location: 'Lexington, Kentucky',
            description: 'Successfully raised over $203 million through direct participation oil and gas limited partnerships.'
        }
    ],
    skills: ['Regulatory Risk']
};

const SteveTeamPage = () => (
    <Fragment>
        <header className="headerArea">
            <HeaderBottom className="headerBottomArea" />
        </header>
        <Breadcumb
            className="breadcumbArea"
            title={`Meet ${teamDetails.name}`}
            breadcumbMenu={breadcumbMenu}
            background={breadcumb}
        />
        <div className="singleTeamArea">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-12">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="singleTeamImgWrap">
                                    <div className="singleTeamImg">
                                        <img src={team} alt={teamDetails.name} />
                                    </div>
                                    <h4>{teamDetails.name}</h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="singleTeamContent">
                                <ul className="teamMembarInfo">
                                    <li><span>Position: </span>{teamDetails.position}</li>
                                        <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                        <li><span>Experience: </span>{teamDetails.experience}</li>
                                        <li><span>Office: </span>{teamDetails.office}</li>
                                        <li><span>Phone: </span>{teamDetails.phone}</li>
                                        <li><span>Email: </span>{teamDetails.email}</li>
                                    </ul>
                                    <ul className="socialShare">
                                        <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="singleTeamInfo">
                                    <h3>Summary</h3>
                                    <p>{teamDetails.summary}</p>

                                    <h3>Professional Experience</h3>
                                    {teamDetails.professionalExperience.map((job, index) => (
                                        <div key={index}>
                                            <h4>{job.role} - {job.company}</h4>
                                            <p><strong>Duration:</strong> {job.duration}</p>
                                            <p><strong>Location:</strong> {job.location}</p>
                                            <p>{job.description}</p>
                                        </div>
                                    ))}

                                    <h5>Education</h5>
                                    <ul>
                                        {teamDetails.education.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>

                                    <h5>Skills</h5>
                                    <ul>
                                        {teamDetails.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactArea className="contactArea" />
        <Testmonial className="testmonialArea" />
        <FooterArea />
    </Fragment>
);

export default SteveTeamPage;