export const dallasOffice = {
    title: "Our Legal Services in Dallas",
    description: "We offer a wide range of legal services to meet your needs.",
    services: [
        {
            title: "Personal Injury Law",
            description: "Representing clients injured due to accidents, negligence, or wrongful conduct in the Dallas area.",
            items: [
                "Car Accidents",
                "Truck Accidents",
                "Workplace Injuries",
                "Slip and Fall",
                "Medical Malpractice"
            ]
        },
        {
            title: "Family Law",
            description: "Providing compassionate legal support for families in Dallas during difficult transitions.",
            items: [
                "Divorce",
                "Child Custody",
                "Child Support",
                "Adoption",
                "Prenuptial Agreements"
            ]
        },
        {
            title: "Criminal Defense",
            description: "Defending your rights and freedom with aggressive criminal defense representation.",
            items: [
                "DUI/DWI Defense",
                "Drug Charges",
                "White Collar Crimes",
                "Assault Charges",
                "Federal Crimes"
            ]
        },
        // Add more service categories as needed
    ]
};