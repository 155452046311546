import React, { Fragment } from 'react';
import HeaderBottom from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import ContactArea from '../../../components/ContactArea';
import Testmonial from '../../../components/Testmonial';

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg';
import team from '../../../images/expert/alex_campbell_photoshoot.jpg';

import './style.scss';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Alex Campbell' }
];

const teamDetails = {
    name: 'Alex Campbell',
    position: 'NFL Agent and Attorney',
    practiceArea: 'Commercial Litigation, Arbitration, Bankruptcy, Immigration Law',
    experience: '9+ years',
    office: 'Dallas, Texas',
    phone: '(405)-310-4333',
    email: 'infomation@velielaw.com',
    education: [
        'Juris Doctor, University of Oklahoma College of Law (2013 - 2016)',
        'Bachelor of Science, Business Administration; Finance, Oklahoma State University (2009 - 2013)'
    ],
    summary: `
        Licensed attorney with significant experience in commercial litigation and arbitration. 
        Recently certified as an NFL Agent, Alex handles cases from start to finish with a focus on 
        delivering strong legal outcomes for clients.
    `,
    professionalExperience: [
        {
            role: 'Attorney',
            company: 'Campbell Law',
            duration: 'February 2024 - Present',
            location: 'Dallas-Fort Worth Metroplex',
            description: 'Handling a variety of commercial litigation cases, providing legal guidance from start to resolution.'
        },
        {
            role: 'Attorney',
            company: 'Ferguson Braswell Fraser Kubasta PC',
            duration: 'June 2021 - March 2024',
            location: 'Dallas, Texas',
            description: 'Managed complex litigation cases and arbitration proceedings.'
        },
        {
            role: 'Attorney',
            company: 'Underwood Perkins, P.C.',
            duration: 'February 2018 - June 2021',
            location: 'Dallas, Texas',
            description: 'Handled commercial litigation cases and bankruptcy proceedings.'
        },
        {
            role: 'Partnership Specialist',
            company: 'U.S. Department of State',
            duration: 'December 2016 - December 2017',
            location: 'Washington, D.C.',
            description: `
                Managed public-private partnerships aligned with U.S. foreign policy. 
                Led a flagship partnership with Google and Intel, raising $550,000 to fund a STEAM camp for high school girls from the U.S. and Africa.`
        }
    ],
    skills: ['Commercial Litigation', 'Arbitration', 'Recruiting']
};

const AlexTeamPage = () => (
    <Fragment>
        <header className="headerArea">
            <HeaderBottom className="headerBottomArea" />
        </header>
        <Breadcumb
            className="breadcumbArea"
            title={`Meet ${teamDetails.name}`}
            breadcumbMenu={breadcumbMenu}
            background={breadcumb}
        />
        <div className="singleTeamArea">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-12">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="singleTeamImgWrap">
                                    <div className="singleTeamImg">
                                        <img src={team} alt={teamDetails.name} />
                                    </div>
                                    <h4>{teamDetails.name}</h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="singleTeamContent">
                                    <h4>Important Information</h4>
                                    <ul className="teamMembarInfo">
                                        <li><span>Position: </span>{teamDetails.position}</li>
                                        <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                        <li><span>Experience: </span>{teamDetails.experience}</li>
                                        <li><span>Office: </span>{teamDetails.office}</li>
                                        <li><span>Phone: </span>{teamDetails.phone}</li>
                                        <li><span>Email: </span><a href={`mailto:${teamDetails.email}`}>{teamDetails.email}</a></li>
                                    </ul>
                                    <ul className="socialShare">
                                        <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="singleTeamInfo">
                                    <h3>Professional Summary</h3>
                                    <p>{teamDetails.summary}</p>

                                    <h3>Professional Experience</h3>
                                    {teamDetails.professionalExperience.map((job, index) => (
                                        <div key={index}>
                                            <h4>{job.role} - {job.company}</h4>
                                            <p><strong>Duration:</strong> {job.duration}</p>
                                            <p><strong>Location:</strong> {job.location}</p>
                                            <p>{job.description}</p>
                                        </div>
                                    ))}

                                    <h5>Education</h5>
                                    <ul>
                                        {teamDetails.education.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>

                                    <h5>Skills</h5>
                                    <ul>
                                        {teamDetails.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactArea className="contactArea" />
        <Testmonial className="testmonialArea" />
        <FooterArea />
    </Fragment>
);

export default AlexTeamPage;