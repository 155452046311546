import React from 'react';
import './style.scss';
import businessInsiderLogo from '../../images/logo/business-insider-v2.png';
import usaTodayLogo from '../../images/logo/usa-today-v2.png';
import digitalJournalLogo from '../../images/logo/digital_journal_logo.png';
import laWeeklyLogo from '../../images/logo/la-weekly-v4.png';
import ailaLogo from '../../images/logo/aila-logo-v2.png';
import ritzHeraldLogo from '../../images/logo/ritz-herald-v2.png';
import washingtonpostLogo from '../../images/logo/washington-post.jpg';

const defaultFeaturedData = [
    { id: 1, logo: businessInsiderLogo, alt: 'Business Insider Logo', link: 'https://www.businessinsider.com/how-to-make-money-amazon-entrepreneurship-ecommerce-business-side-hustle-2022-11' },
    { id: 2, logo: washingtonpostLogo, alt: 'Washington Post Logo', link: 'https://www.washingtonpost.com/archive/politics/2004/12/19/revisionist-history-in-the-cards/bedd91b4-a8ca-4a9c-aa68-f8efd64ca558/' },
    { id: 3, logo: usaTodayLogo, alt: 'USA Today Logo', link: 'https://www.usatoday.com/story/special/contributor-content/2023/12/19/the-best-use-of-ai-and-the-law-how-jon-velies-visas-ai-improves-lawyers-success-and-speed-with-ai-in/71975401007/' },
    { id: 4, logo: ailaLogo, alt: 'American Immigration Lawyers Association Logo', link: 'https://www.aila.org/files/o-files/view-file/342DDC90-BB0F-492B-9094-D19672EA7CA6' },
    { id: 5, logo: digitalJournalLogo, alt: 'Digital Journal Logo', link: 'https://www.digitaljournal.com/life/jon-velies-tech-focused-perspective-on-immigration-law/article' },
    { id: 6, logo: ritzHeraldLogo, alt: 'Ritz Herald Logo', link: 'https://ritzherald.com/disrupting-immigration-law-how-onlinevisas-ai-platform-vias-ai-is-helping-immigration-attorneys/' },
    { id: 7, logo: laWeeklyLogo, alt: 'LA Weekly Logo', link: 'https://www.laweekly.com/2024/09/26/meet-the-company-leveraging-ai-to-file-better-u-s-immigration-cases-helping-refugees/' },
];

const FeaturedOn = ({ customLogos = [], customBrands = [], displayCount = 0, headingText = "Press About Our Firm:", customClass = '' }) => {
    // Prioritize customBrands if provided, otherwise use customLogos
    const logosToDisplay = customBrands.length > 0 ? customBrands : (customLogos.length > 0 ? customLogos : defaultFeaturedData);
    const logosToShow = displayCount > 0 ? logosToDisplay.slice(0, displayCount) : logosToDisplay;
  
    return (
        <div className={`featured-on-container ${customClass}`}>
          <h2>{headingText}</h2>
          <div className="logos">
              {logosToShow.map(({ id, logo, alt, link }) => (
                  <a key={id} href={link || "#"} target="_blank" rel="noopener noreferrer">
                      <img src={logo} alt={alt || "Logo"} className="logo" />
                  </a>
              ))}
          </div>
      </div>
  );
};

export default FeaturedOn;