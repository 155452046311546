import React, { Fragment } from 'react';
import HeaderBottom from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import ContactArea from '../../../components/ContactArea';
import Testmonial from '../../../components/Testmonial';

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg';
import team from '../../../images/expert/brian_bagley_photoshoot.jpg';

import './style.scss';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Brian Bagley' }
];

const teamDetails = {
    name: 'Brian Bagley',
    position: 'Attorney',
    practiceArea: 'Family Law, Divorce',
    experience: '12+ years',
    office: 'McKinney, Texas, United States',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    education: [
        'Texas A&M School of Law (FKA Texas Wesleyan School of Law), Fort Worth, TX - Juris Doctorate, cum laude, 2011',
        'University of North Texas, Denton, TX - Bachelor of Arts, Economics, 2004'
    ],
    summary: `
        Attorney Brian Bagley founded the Law Office of Brian Bagley, PLLC, to bring compassionate 
        legal services to the families and individuals of Northern Texas. Mr. Bagley is a Texas native 
        who graduated cum laude from Texas Wesleyan University School of Law in 2011. He has a strong 
        background in litigation, so he is not afraid to bring your case in front of the courts if that 
        is best for you. Our philosophy is to always focus on doing what is best for our client. We take 
        the time to listen to your side of the story, to better understand your needs and goals. If you 
        need guidance on any type of family law or divorce-related issue, you can count on him to give 
        you the personalized attention and advice you deserve.
    `,
    professionalExperience: [
        {
            role: 'Founder & Attorney',
            company: 'Law Office of Brian Bagley, PLLC',
            duration: '2011 - Present',
            location: 'McKinney, Texas',
            description: 'Provides compassionate legal services focusing on family law and divorce matters for clients throughout Northern Texas.'
        }
    ],
    skills: ['Family Law', 'Divorce', 'Litigation'],
    barAdmissions: ['Texas, 2011'],
    honors: ['Texas A&M Law Review, 2010-2011'],
};

const BrianTeamPage = () => (
    <Fragment>
        <header className="headerArea">
            <HeaderBottom className="headerBottomArea" />
        </header>
        <Breadcumb
            className="breadcumbArea"
            title={`Meet ${teamDetails.name}`}
            breadcumbMenu={breadcumbMenu}
            background={breadcumb}
        />
        <div className="singleTeamArea">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-12">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="singleTeamImgWrap">
                                    <div className="singleTeamImg">
                                        <img src={team} alt={teamDetails.name} />
                                    </div>
                                    <h4>{teamDetails.name}</h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="singleTeamContent">
                                    <h4>Important Information</h4>
                                    <ul className="teamMembarInfo">
                                    <li><span>Position: </span>{teamDetails.position}</li>
                                        <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                        <li><span>Experience: </span>{teamDetails.experience}</li>
                                        <li><span>Office: </span>{teamDetails.office}</li>
                                        <li><span>Phone: </span>{teamDetails.phone}</li>
                                        <li><span>Email: </span>{teamDetails.email}</li>
                                    </ul>
                                    <ul className="socialShare">
                                        <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="singleTeamInfo">
                                    <h3>Summary</h3>
                                    <p>{teamDetails.summary}</p>

                                    <h3>Professional Experience</h3>
                                    {teamDetails.professionalExperience.map((job, index) => (
                                        <div key={index}>
                                            <h4>{job.role} - {job.company}</h4>
                                            <p><strong>Duration:</strong> {job.duration}</p>
                                            <p><strong>Location:</strong> {job.location}</p>
                                            <p>{job.description}</p>
                                        </div>
                                    ))}

                                    <h5>Bar Admissions</h5>
                                    <ul>
                                        {teamDetails.barAdmissions.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>

                                    <h5>Education</h5>
                                    <ul>
                                        {teamDetails.education.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>

                                    <h5>Honors and Awards</h5>
                                    <ul>
                                        {teamDetails.honors.map((honor, index) => (
                                            <li key={index}>{honor}</li>
                                        ))}
                                    </ul>

                                    <h5>Practice Areas</h5>
                                    <ul>
                                        {teamDetails.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactArea className="contactArea" />
        <Testmonial className="testmonialArea" />
        <FooterArea />
    </Fragment>
);

export default BrianTeamPage;
