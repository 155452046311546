import React, { Fragment, Component } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import HomePageOne from '../Pages/HomePageOne'
import HomePageTwo from '../Pages/HomePageTwo'
import HomePageThree from '../Pages/HomePageThree'
import AboutPage from '../Pages/AboutPage'
import PracticePage from '../Pages/PracticePage'
import PracticeSinglePage from '../Pages/PracticeSinglePage'
import PortfolioPage from '../Pages/PortfolioPage'
import SinglePortfolioPage from '../Pages/SinglePortfolioPage'
import J1BlogPage from '../Pages/J1BlogPage'
import B1BlogPage from '../Pages/B1BlogPage'
import HaitiTPSBlogPage from '../Pages/HaitiTPSBlogPage'
import USCISPolicyBlogPage from '../Pages/USCISPolicyBlogPage'
import STEMUpdateBlogPage from '../Pages/STEMUpdateBlogPage'
import H1BCapUpdateBlogPage from '../Pages/H1BCapUpdateBlogPage'
import H2ABlogPage from '../Pages/H2ABlogPage'
import EB5BlogPage from '../Pages/EB5BlogPage'
import TeamPage from '../Pages/TeamPage'
import SingleTeamPage from '../Pages/SingleTeamPage'
import YeshTeamPage from '../Pages/YeshTeamPage'
import KevinTeamPage from '../Pages/KevinTeamPage'
import AshleyTeamPage from '../Pages/AshleyTeamPage'
import NickTeamPage from '../Pages/NickTeamPage'
import AlexTeamPage from '../Pages/AlexTeamPage'
import DrakeTeamPage from '../Pages/DrakeTeamPage'
import MatthewTeamPage from '../Pages/MatthewTeamPage'
import BrianTeamPage from '../Pages/BrianTeamPage'
import SteveTeamPage from '../Pages/SteveTeamPage'
import ContactPage from '../Pages/ContactPage'
import DallasPage from '../Pages/DallasPage'
import WebinarPage from '../Pages/WebinarPage'
import BlogLeftPage from '../Pages/BlogLeftPage'
import BlogRightPage from '../Pages/BlogRightPage'
import BlogFullWidth from '../Pages/BlogFullWidth'
import H1BPage from '../Pages/VisaPages/H1BPage'
import P1Page from '../Pages/VisaPages/P1Page'
import EB5Page from '../Pages/VisaPages/EB5Page'
import E2Page from '../Pages/VisaPages/E2Page'
import IEPPage from '../Pages/VisaPages/IEPPage'
import L1Page from '../Pages/VisaPages/L1Page'
import EB1CPage from '../Pages/VisaPages/EB1CPage'
import J1Page from '../Pages/VisaPages/J1Page'
import O1Page from '../Pages/VisaPages/O1Page'
import EB1Page from '../Pages/VisaPages/EB1Page'
import B1Page from '../Pages/VisaPages/B1Page'
import E1Page from '../Pages/VisaPages/E1Page'
import E3Page from '../Pages/VisaPages/E3Page'
import H3Page from '../Pages/VisaPages/H3Page'
import IPage from '../Pages/VisaPages/IPage'
import P3Page from '../Pages/VisaPages/P3Page'
import R1Page from '../Pages/VisaPages/R1Page'
import TNPage from '../Pages/VisaPages/TNPage'
import EB1BPage from '../Pages/VisaPages/EB1BPage'
import EB2Page from '../Pages/VisaPages/EB2Page'
import K1Page from '../Pages/VisaPages/K1Page'
import K2Page from '../Pages/VisaPages/K2Page'
import K3Page from '../Pages/VisaPages/K3Page'
import K4Page from '../Pages/VisaPages/K4Page'
import IRPage from '../Pages/VisaPages/IRPage'
import CLPage from '../Pages/VisaPages/CLPage'
import MNAPage from '../Pages/VisaPages/MNAPage'
import PracticeServicePage from '../Pages/PracticeServicePage'
import LitigationPage from '../Pages/VisaPages/LitigationPage'
import FLPage from '../Pages/VisaPages/FLPage'
import TLPage from '../Pages/VisaPages/TLPage'
import FPPage from '../Pages/VisaPages/FPPage'
import WorkPage from '../Pages/VisaPages/WorkPage'
import FamilyPage from '../Pages/VisaPages/FamilyPage'
import NATPage from '../Pages/VisaPages/NATPage'
import DACAPage from '../Pages/VisaPages/DACAPage'
import H1B1Page from '../Pages/VisaPages/H1B1Page'
import './App.css'


class App extends Component {

    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    <ToastContainer autoClose={2500} position="top-center" />
                    <Routes>
                        <Route path="/" element={<HomePageOne />} />
                        <Route path="home-two" element={<HomePageTwo />} />
                        <Route path="home-three" element={<HomePageThree />} />
                        <Route path="about" element={<AboutPage />} />
                        <Route path="practice" element={<PracticePage />} />
                        <Route path="practice-details" element={<PracticeSinglePage />} />
                        <Route path="case-studies" element={<PortfolioPage />} />
                        <Route path="case-studies-details" element={<SinglePortfolioPage />} />
                        <Route path="b1-journey" element={<B1BlogPage />} />
                        <Route path="j1-journey" element={<J1BlogPage />} />
                        <Route path="uscis-policy-update" element={<USCISPolicyBlogPage />} />
                        <Route path="haiti-tps-update" element={<HaitiTPSBlogPage />} />
                        <Route path="uscis-stem-update" element={<STEMUpdateBlogPage />} />
                        <Route path="h1b-cap-update" element={<H1BCapUpdateBlogPage />} />
                        <Route path="h2a-agriculture" element={<H2ABlogPage />} />
                        <Route path="eb5-rate-hikes" element={<EB5BlogPage />} />
                        <Route path="attorneys" element={<TeamPage />} />
                        <Route path="jon-velie" element={<SingleTeamPage />} />
                        <Route path="kevin-vance" element={<KevinTeamPage />} />
                        <Route path="ashley-morin" element={<AshleyTeamPage />} />
                        <Route path="yesh-vasamsetty" element={<YeshTeamPage />} />
                        <Route path="nick-jordan" element={<NickTeamPage />} />
                        <Route path="drake-nelson" element={<DrakeTeamPage />} />
                        <Route path="alex-campbell" element={<AlexTeamPage />} />
                        <Route path="matthew-shlake" element={<MatthewTeamPage />} />
                        <Route path="brian-bagley" element={<BrianTeamPage />} />
                        <Route path="steve-carson" element={<SteveTeamPage />} />
                        <Route path="dallas" element={<DallasPage />} />
                        <Route path="contact" element={<ContactPage />} />
                        <Route path="webinar" element={<WebinarPage />} />
                        <Route path="blog-left" element={<BlogLeftPage />} />
                        <Route path="blog-right" element={<BlogRightPage />} />
                        <Route path="blog-fullwidth" element={<BlogFullWidth />} />
                        <Route path="h1b-visa" element={<H1BPage />} />
                        <Route path="p1-visa" element={<P1Page />} />
                        <Route path="eb5-visa" element={<EB5Page />} />
                        <Route path="e2-visa" element={<E2Page />} />
                        <Route path="iep" element={<IEPPage />} />
                        <Route path="l1-visa" element={<L1Page />} />
                        <Route path="eb1c-visa" element={<EB1CPage />} />
                        <Route path="j1-visa" element={<J1Page />} />
                        <Route path="o1-visa" element={<O1Page />} />
                        <Route path="eb1-visa" element={<EB1Page />} />
                        <Route path="b1-visa" element={<B1Page />} />
                        <Route path="e1-visa" element={<E1Page />} />
                        <Route path="e3-visa" element={<E3Page />} />
                        <Route path="h3-visa" element={<H3Page />} />
                        <Route path="i-visa" element={<IPage />} />
                        <Route path="p3-visa" element={<P3Page />} />
                        <Route path="r1-visa" element={<R1Page />} />
                        <Route path="tn-visa" element={<TNPage />} />
                        <Route path="eb1b-visa" element={<EB1BPage />} />
                        <Route path="eb2-visa" element={<EB2Page />} />
                        <Route path="k1-visa" element={<K1Page />} />
                        <Route path="k2-visa" element={<K2Page />} />
                        <Route path="k3-visa" element={<K3Page />} />
                        <Route path="k4-visa" element={<K4Page />} />
                        <Route path="spouse-visa" element={<IRPage />} />
                        <Route path="practices" element={<PracticeServicePage />} />
                        <Route path="corporate-law" element={<CLPage />} />
                        <Route path="mergers-acquisitions" element={<MNAPage />} />
                        <Route path="litigation" element={<LitigationPage />} />
                        <Route path="family-law" element={<FLPage />} />
                        <Route path="tax-law" element={<TLPage />} />
                        <Route path="family-preference" element={<FPPage />} />
                        <Route path="work-visa" element={<WorkPage />} />
                        <Route path="family-visa" element={<FamilyPage />} />
                        <Route path="naturalization" element={<NATPage />} />
                        <Route path="daca-applicant" element={<DACAPage />} />
                        <Route path="h1b1-visa" element={<H1B1Page />} />
                    </Routes>
                </BrowserRouter>
            </Fragment >
        );
    }
}

export default App;
