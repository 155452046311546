import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from '../Title'
import ContactArea from "../ContactArea";
import './style.scss'

// images
import teamMember1 from '../../images/expert/jon_velie_photoshoot.jpg'
import teamMember2 from '../../images/expert/alex_campbell_photoshoot.jpg'
import teamMember3 from '../../images/expert/matthew_shclake_photoshoot.jpg'
import teamMember4 from '../../images/expert/yesh_vasamsetty_photoshoot.jpg'
import teamMember5 from '../../images/expert/brian_bagley_photoshoot.jpg'
import teamMember6 from '../../images/expert/ashley_morin_photoshoot.jpg'
import teamMember7 from '../../images/expert/steve_carson_photoshoot.jpg'
import teamMember8 from '../../images/expert/nick_jordan_photoshoot.jpg'

const teams = [
    {
        name: 'Jon Velie', level: 'Immigration Attorney', url:'/jon-velie', image: teamMember1, socialMedia: [
            'facebook', 'linkedin'
        ]
    },
    {
        name: 'Alex Campbell', level: 'NFL Agent and Attorney', url: '/alex-campbell', image: teamMember2, socialMedia: [
            'facebook', 'linkedin'
        ]
    },
    {
        name: 'Matthew Schlake', level: 'Criminal Defense Attorney', url: '/matthew-shlake', image: teamMember3, socialMedia: [
            'facebook', 'linkedin'
        ]
    },
    {
        name: 'Yesh Vasamsetty', level: 'Immigration Attorney', url:'/yesh-vasamsetty', image: teamMember4, socialMedia: [
            'facebook', 'Linkedin'
        ]
    },
    {
        name: 'Brian Bagley', level: 'Family Law Attorney', url: '/brian-bagley', image: teamMember5, socialMedia: [
            'facebook', 'linkedin'
        ]
    },
    {
        name: 'Ashley Morin', level: 'Immigration Attorney', url: '/ashley-morin', image: teamMember6, socialMedia: [
            'facebook', 'linkedin'
        ]
    },
    {
        name: 'Steve Carson', level: 'Corporate Law', url: '/steve-carson', image: teamMember7, socialMedia: [
            'facebook', 'linkedin'
        ]
    },
    {
        name: 'Nick Jordan', level: 'Immigration Attorney', url: '/nick-jordan', image: teamMember8, socialMedia: [
            'facebook', 'linkedin'
        ]
    }
]

const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const socialMediaUrls = {
    facebook: "https://www.facebook.com/VelieLawFirm/",
    linkedin: "https://www.linkedin.com/company/velie-law-firm-pllc/"
};

const TeamMember = ({ className, title, subTitle, slider, noGutters, extraClass }) => {
    
    const teamMemberClass = `${className} ${extraClass || ''}`;
    
    return (
        <div className={teamMemberClass}>
            <div className="container">
                <div className={!noGutters ? 'row' : 'row no-gutters'}>
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {slider ? (
                        <div className="col-12">
                            <Slider className="teamSlideArea" {...settings}>
                                {teams.map((team, i) => (
                                    <div key={i} className="teamWrapper">
                                        <div className="teamImage">
                                            <img src={team.image} alt="Attorney Headshot Images" />
                                        </div>
                                        <div className="teamContent">
                                            <h3><a href={team.url}>{team.name}</a></h3>
                                            <span>{team.level}</span>
                                            <ul>
                                            {team.socialMedia.map(social => (
                                                <li key={social}>
                                                    <a href={socialMediaUrls[social.toLowerCase()] || '#'}>
                                                        <i className={`fa fa-${social.toLowerCase()}`} aria-label={`Follow on ${social.charAt(0).toUpperCase() + social.slice(1)}`}></i>
                                                    </a>
                                                </li>
                                            ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                            <Fragment>
                                {teams.map((team, i) => (
                                    <div key={i} className="col-lg-4 col-md-6 col-12">
                                        <div className="teamWrapper">
                                            <div className="teamImage">
                                                <img src={team.image} alt="Attorney Headshot Images" />
                                            </div>
                                            <div className="teamContent">
                                                <h3><a href={team.url}>{team.name}</a></h3>
                                                <span>{team.level}</span>
                                                <ul>
                                                {team.socialMedia.map(social => (
                                                <li key={social}>
                                                    <a href={socialMediaUrls[social.toLowerCase()] || '#'}>
                                                        <i className={`fa fa-${social.toLowerCase()}`} aria-label={`Follow on ${social.charAt(0).toUpperCase() + social.slice(1)}`}></i>
                                                    </a>
                                                </li>
                                                ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            <ContactArea className="contactArea" />
                            </Fragment>
                        )}
                </div>
            </div>
        </div>
    )
}
export default TeamMember