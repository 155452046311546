import './style.scss';
import React, { Fragment, useEffect } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import TeamMember from '../../../components/TeamMember';
import Portfolio from '../../../components/Portfolio';
import PortfolioItems from '../../../data/PortfolioItems';
import OfficeArea from '../../../components/OfficesArea';
import { dallasOffice } from '../../../data/OfficeItems/dallasServices'; // Adjust the path as necessary

// images
import breadcumb from '../../../images/breadcumb/dallas_office_page.jpg';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Contact', route: '/contact' },
    { name: 'Dallas Office' },
];

const DallasPage = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelOne" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Dallas Office"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contactUsInfo">
                                <h4>Dallas Office Address</h4>
                                <span>555 Republic Dr. Ste 490 Plano,<br /> TX 75074</span>
                                <h4>Phone</h4>
                                <span>(405) 310-3133</span>
                                <h4>Email</h4>
                                <span>information@velielaw.com</span>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contactFormArea">
                                <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1I2Hcf85yNnQ8tKpmoklFF5ImjOViI9x9qVEKKEKbhK80I3gO3xdOeC2vPiloROH9"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OfficeArea office={dallasOffice} /> 
            <TeamMember
                title="Qualified Attorneys"
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
                extraClass="contactTeamAreaBackground contactTeamContent"
            />
            <Portfolio
                className="portfolioArea"
                title="Immigration Cases"
                subTitle="Our Work"
                items={PortfolioItems}
            />
            <FooterArea />
        </Fragment>
    );
};

export default DallasPage; 