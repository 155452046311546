import React, { useEffect } from 'react'

import './style.scss'

const MNAContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>Mergers and Acquisitions Services</h1> 
            <p>In today's dynamic business landscape, mergers and acquisitions (M&A) are powerful strategies for growth, diversification, and gaining a competitive edge. Our firm specializes in providing comprehensive legal services to guide businesses through every stage of the M&A process, ensuring transactions are executed smoothly and successfully.</p> 
            <h2>Why Choose Us for Mergers and Acquisitions?</h2>
            <p>We understand that every M&A deal is unique, presenting its own set of challenges and opportunities. Our experienced M&A attorneys offer personalized legal solutions tailored to your specific objectives. Whether you're aiming to acquire another company, merge with a strategic partner, or divest a business unit, we are here to support you at every step.</p> <h3>Key Areas of Mergers and Acquisitions We Cover:</h3>
            <li><strong>Strategic Planning and Due Diligence</strong></li> 
            <p>We assist in identifying potential targets or buyers and conduct thorough due diligence to uncover opportunities and risks, providing you with the insights needed to make informed decisions.</p>
            <li><strong>Deal Structuring and Negotiation</strong></li>
            <p>Our team offers strategic advice on structuring transactions to meet your goals while optimizing tax and regulatory considerations. We skillfully negotiate terms to achieve the best possible outcome for your business.</p>
            <li><strong>Regulatory Compliance</strong></li>
            <p>Navigating the complex regulatory environment is crucial in M&A transactions. We ensure compliance with all federal, state, and local regulations, including antitrust laws and securities regulations.</p>
            <li><strong>Contract Drafting and Review</strong></li>
            <p>We draft, review, and negotiate all necessary agreements, such as letters of intent, purchase agreements, and shareholder agreements, to protect your interests throughout the transaction.</p>
            <li><strong>Financing and Tax Planning</strong></li>
            <p>Our attorneys provide guidance on financing options and develop tax-efficient structures to maximize the financial benefits of your M&A activities.</p>
            <li><strong>Post-Merger Integration</strong></li>
            <p>We assist with the legal aspects of integrating operations, cultures, and systems post-merger to ensure a seamless transition and realize the full value of the deal.</p>
            <h4>Dedicated Support for Your M&A Success</h4> <p>Our M&A practice is committed to providing you with the legal expertise and strategic guidance needed to navigate complex transactions confidently. We work closely with our clients to align our services with their business objectives, ensuring they are well-positioned for success.</p> <p>If you are considering a merger, acquisition, or divestiture, contact us today to learn how we can assist with your M&A needs.</p>
            <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1ADB5eRz8axnyGQXxLTf2XTk7aKDx0JXc4RgNkyaPG3ZFpA0OqNMmtSCXYGTKTmq7"></div>
            </div>
    </div>
    )
}
export default MNAContentArea