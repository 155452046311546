import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import ContactArea from '../../../components/ContactArea'
import Testmonial from '../../../components/Testmonial'

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg'
import team from '../../../images/expert/ashley-morin.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Ashley Morin' }
]

const teamDetails = {
    name: 'Ashley Morin',
    position: 'Immigration Attorney',
    practiceArea: 'Employment-Based Immigration, Adjustment of Status, Advanced Parole',
    experience: 'Nearly 10 years',
    office: 'Chicago, Illinois',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    memberships: [
        'Illinois State Bar',
        'American Immigration Lawyers Association (AILA)'
    ],
    communityService: [
        'Volunteer Legal Services with local immigration non-profits',
        'Participates in community outreach programs for immigrant families'
    ],
    education: [
        'Juris Doctor, Chicago-Kent College of Law, Illinois Institute of Technology',
        'Bachelor of Arts, Loyola University Chicago'
    ],
    barAdmission: 'Illinois State Bar, Attorney No. 6321166'
};

const AshleyTeamPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBottom className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={`Meet ${teamDetails.name}`}
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={team} alt={teamDetails.name} />
                                        </div>
                                        <h4>{teamDetails.name}</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Important Information</h4>
                                        <ul className="teamMembarInfo">
                                            <li><span>Position: </span>{teamDetails.position}</li>
                                            <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                            <li><span>Experience: </span>{teamDetails.experience}</li>
                                            <li><span>Office: </span>{teamDetails.office}</li>
                                            <li><span>Phone: </span>{teamDetails.phone}</li>
                                            <li><span>Email: </span>{teamDetails.email}</li>
                                        </ul>
                                    <ul className="socialShare">
                                        <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Personal Experience</h3>
                                        <p>Ashley Morin brings considerable experience from boutique, mid-size, and large immigration law firms. She is passionate about the crucial role immigrants play in driving innovation within the U.S. economy. Ashley specializes in business immigration, helping clients in industries like IT, staffing, automotive, and engineering. Her areas of expertise include H-1Bs, L-1s, EB-1, EB-2, and EB-3 visas, as well as Adjustment of Status and Employment Authorization Documents.</p>                                        
                                        <p>Ashley is also a dedicated advocate for her clients, ensuring they feel supported throughout the immigration process. "Knowing the law is not enough," she says. "I strive to make sure every client knows I have their back and will do whatever it takes to get the job done."</p>
                                        <h5>Memberships</h5>
                                        <ul>
                                            {teamDetails.memberships.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Community Service</h5>
                                        <ul>
                                            {teamDetails.communityService.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Education</h5>
                                        <ul>
                                            {teamDetails.education.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>                                        
                                        
                                        <h5>Bar Admission</h5>
                                        <span>{teamDetails.barAdmission}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactArea className="contactArea" />
            <Testmonial className="testmonialArea" />
            <FooterArea />
        </Fragment>
    )
}
export default AshleyTeamPage
