import React from 'react';
import './style.scss';

const OfficeArea = ({ office }) => {
  return (
    <div className="officeServiceArea"> {/* Keep this class for overall styling */}
      <h2 className="brandPromisesHeading">{office.title}</h2> {/* Updated class for title */}
      <p className="description">{office.description}</p> {/* Added class for description */}
      {office.services.map((service, index) => (
        <div key={index} className="service-category">
          <h3>{service.title}</h3>
          <p>{service.description}</p>
          <ul>
            {service.items.map((item, itemIndex) => (
              <li key={itemIndex}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default OfficeArea;