import React, { Fragment } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
import FooterArea from '../../../components/FooterArea';
import Breadcumb from '../../../components/Breadcumb';

import ServiceArea from '../../../components/ServiceArea'
import CounterArea from '../../../components/CounterArea';
import TeamMember from '../../../components/TeamMember';
import Portfolio from '../../../components/Portfolio';
import PortfolioItems from '../../../data/PortfolioItems';
import ImmigrationServices from '../../../components/ImmigrationServices';
import FeaturedOn from '../../../components/Featured';
import Testmonial from "../../../components/Testmonial";
import './style.scss';

// images
import breadcumb from '../../../images/breadcumb/b1-b2-visa.jpg';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Practice Areas' },
]

const services = [
    {
        icon: 'flaticon-network',
        title: 'Business Immigration',
        content: 'As part of the OnlineVisas network, we have the means to obtain visas, work permits, or residency permits.',
        link: '/work-visa',
    },
    {
        icon: 'flaticon-employee',
        title: 'Corporate Law',
        content: 'Expert legal advice to help navigate complex corporate transactions, mergers, and acquisitions.',
        link: '/corporate-law',
    },
    {
        icon: 'flaticon-wounded',
        title: 'Litigation',
        content: 'Providing strong representation in court for both civil and commercial disputes, ensuring your rights are protected.',
        link: '/litigation'
    },
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'Guidance on legal matters related to family, including divorce, child custody, and alimony.',
        link: '/family-law',
    },
    {
        icon: 'flaticon-thief',
        title: 'Tax Law',
        content: 'Expert advice on tax planning, compliance, and resolving disputes to help you manage and minimize tax liabilities.',
        link: '/tax-law',
    },
    {
        icon: 'flaticon-parents',
        title: 'Temporary & Permanent Residency',
        content: 'Expert guidance for securing U.S. visas, for short-term stays or permanent residency.',
        link: '/family-visa',
    },
]

const servicesData = [
    {
      title: 'Temporary Visas',
      items: [
        "B-1 Business Visitor",
        "E-1 Treaty Trader",
        "F-1 Academic Student",
        "H-1B Specialty Occupation",
        "H-2A Agricultural Worker",
        "I Media Representative",
        "J-1 Exchange Visitor",
        "L-1 Intracompany Transferee",
        "O-1 Extraordinary Ability",
        "P-1 Athlete or Entertainer",
    ]    
    },
    {
      title: 'Permanent Residency',
      items: [
        "Investor Green Card",
        "Marriage Green Card",
        "Employment-Based Green Card",
        "Diversity Lottery Green Card",
        "Special Immigrant Green Card",
        "Refugee or Asylee Status Green Card"
    ]
    },
    {
      title: 'U.S. Citizenship',
      items: [
        "Citizenship through Naturalization",
        "Citizenship through Parents",
        "Dual Citizenship",
        "Certificate of Citizenship",
        "Citizenship Test and Interview",
        "Oath of Allegiance Ceremony"
    ]
    }
  ];

const PracticeServicePage = () => {
      
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Practice Areas"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <ServiceArea
                className="ourServiceArea"
                title="Areas of Practice"
                subTitle="Ways Our Firms Can Serve You"
                services={services}
            />
            
            <Testmonial
                className="testmonialArea pt100"
            />
            <FeaturedOn 
                className="featured-on-container"
            />
            <ImmigrationServices 
                className="immigrationServiceArea"
                services={servicesData}
            />
            <CounterArea
                className="counterArea"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
                extraClass="contactTeamAreaBackground contactTeamContent"
            />
            <Portfolio
                className="portfolioArea"
                title="From Visa to Green Card"
                subTitle="Our Work"
                items={PortfolioItems.slice(5, 20)}
            />
            <FooterArea />
        </Fragment>
    )
}
export default PracticeServicePage