import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import ContactArea from '../../../components/ContactArea'
import Testmonial from '../../../components/Testmonial'

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg'
import team from '../../../images/expert/nick_jordan_photoshoot.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Nick Jordan' }
]

const teamDetails = {
    name: 'Nick Jordan',
    position: 'Immigration Attorney',
    practiceArea: 'Immigration Law',
    experience: '5+ years',
    offices: 'Dallas, Texas',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    memberships: [
        'Texas Bar Association',
        'American Immigration Lawyers Association'       
    ],
    communityService: [
        'Dallas Habitat for Humanity',
        'Local youth mentorship programs'
    ],
    education: [
        'Juris Doctor, Marquette University Law School',
        'Bachelor of Business Administration, Texas A&M University'
    ]
};

const NickTeamPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={`Meet ${teamDetails.name}`}
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={team} alt={teamDetails.name} />
                                        </div>
                                        <h4>{teamDetails.name}</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Important Information</h4>
                                        <ul className="teamMembarInfo">
                                            <li><span>Position: </span>{teamDetails.position}</li>
                                            <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                            <li><span>Experience: </span>{teamDetails.experience}</li>
                                            <li><span>Offices: </span>{teamDetails.offices}</li>
                                            <li><span>Phone: </span>{teamDetails.phone}</li>
                                            <li><span>Email: </span>{teamDetails.email}</li>
                                        </ul>
                                        <ul className="socialShare">
                                            <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Personal Experience</h3>
                                        <p>Nick Jordan is an accomplished immigration attorney at Velie Law Firm, PLLC. With experience in navigating complex immigration regulations, Nick combines legal expertise and practical business strategies. His career spans various roles in law and business, including immigration law at prominent firms and sales management at top companies.</p>
                                        <p>Nick's practice focuses on helping individuals and businesses overcome legal challenges related to U.S. immigration. His unique background in sales and law makes him a versatile professional capable of guiding clients through both legal and financial landscapes. He has played a key role in representing clients across industries, ensuring compliance and fostering growth.</p>                                        
                                        <p>Nick is also involved in community service and offers advice on choosing insurance coverage to secure the future of both individuals and businesses.</p>                                        
                                        <h5>Memberships</h5>
                                        <ul>
                                            {teamDetails.memberships.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Community Service</h5>
                                        <ul>
                                            {teamDetails.communityService.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Education</h5>
                                        <ul>
                                            {teamDetails.education.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>                                    
                                        <h5>Language</h5>
                                        <span>English (fluent)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactArea className="contactArea" />
            <Testmonial className="testmonialArea" />
            <FooterArea />
        </Fragment>
    )
}
export default NickTeamPage
