import React, { useEffect } from 'react';
import './style.scss'

const H1BContentArea = ({ className, image }) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>H-1B Visa</h1>
            <p>The H-1B visa (or “Specialty Occupation” visa) is one of the most popular temporary worker visas used for those in professional or specialty occupations.</p>
            <p>It allows the visa holder to work in the United States for three years with a three-year extension.</p>
            <p>(Nationals of Chile or Singapore may also consider the specific H-1B1 Visa sub-type.)</p>
            
            <h2>H-1B Visa Benefits</h2>
            <ul>
                <li>Your immediate family can stay with you as long as you maintain your H-1B status. Families can attend school.</li>
                <li>You can freely travel in and out of the U.S. (with valid visa)</li>
                <li>H-1B is valid for three years with an additional three year extension (total of six years). This may be further extended if the holder has begun the Green Card process.</li>
            </ul>

            <h2>H-1B Visa Requirements</h2>
            <ul>
                <li>Requires a bachelor's degree or higher or its equivalent in the specific specialty</li>
                <li>The degree requirement is the normal minimum requirement for the position or for the industry.</li>
                <li>Requires theoretical & practical body of knowledge obtained generally through studies at institution of higher learning (though there are work experience equivalent - three years of work is equal to one year of education)</li>
                <li>Specialized fields include (not are not limited to): architecture theology international business management engineering mathematics sciences programming journalism accountancy research medicine/health nursing law arts social sciences education (if your position is not listed please contact us to find out if your position qualifies)</li>
            </ul>

            <h2>H-1B Visa Additional Details</h2>
            <h3>Family Members of H1B Holders</h3>
            <p>Spouses and/or unmarried children under the age of 21 may join the H-1B holder in the U.S. for as long as the H-1B visa is valid.</p>
            <p>Family members will hold an H-4 visa. H-4 visa holders may attend school but may not work under the H-4. Family members would need to qualify for another visa that would permit work.</p>

            <h3>H-1B Cap</h3>
            <p>A maximum of 65000 H-1B's are issued each year.</p>
            <p>There are some exceptions - the first 20000 with a U.S. Master's degree (or higher) are exempt. Also those applying for positions at institutions of higher education or its affiliated or related non-profit or research entities are also exempt from the cap.</p>
            <p>The cap plays a larger role in some years than others. We can advise you regarding H-1B cap issues.</p>

            <h3>“Labor Certification” or Labor Condition Application (LCA)</h3>
            <p>The employer must approve it and will pay the visa holder a wage that is no less than similarly qualified workers i.e. the “prevailing wage” for the position in that geographic area.</p>
            <p>The prevailing wage determination is a complex matter and can be a sticking point in the visa application.</p>
            <p>We are very experienced in successfully navigating the prevailing wage determination issue using a variety of alternative methods.</p>

            <h3>H-1B Extension & Permanent Residency</h3>
            <p>H-1B visas are limited to three years with a three year extension. It is critical you apply for the extension prior to the dates specified by USCIS.</p>
            <p>You may be able to stay beyond the cumulative six year period of the H-1B and extension if you apply for permanent residency/Green Card while holding an H-1B visa. But it is important you apply for the Green Card by the end of year two of your extension.</p>
            <p>Our firm can assist with H-1B extensions as well as the permanent residency process for H-1B holders.</p>
            </div>
            <iframe
            src="https://webint.visas.ai/Z3VpZGVudEBnbWFpbC5jb20="
            width="100%"
            height="100%"/>
            </div>
    );
};

export default H1BContentArea